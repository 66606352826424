import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useCheckoutQuery } from '@nordic-web/gql'
import { useDidUpdateEffect } from '@nordic-web/utils/hooks/use-did-update-effect'
import { useAuthenticationStore } from '@/features/auth/authentication-store'
import { voucherQueryKey } from '@/features/checkout/hooks/use-subscription'
import { stringFromQueryParam } from '@/utils/query-string'

export const useCheckoutPage = (packageId?: string) => {
  const router = useRouter()
  const queryPackageId = packageId || (stringFromQueryParam(router.query?.packageId) ?? '')
  const { isLoggedIn } = useAuthenticationStore()
  const queryClient = useQueryClient()

  const checkoutQuery = useCheckoutQuery({
    variables: { packageId: queryPackageId },
    fetchPolicy: 'network-only',
  })

  useDidUpdateEffect(() => {
    if (isLoggedIn && checkoutQuery.data) {
      checkoutQuery.refetch()
      if (router.query.voucherCode) {
        queryClient.fetchQuery({ queryKey: voucherQueryKey })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, isLoggedIn)

  return checkoutQuery
}
