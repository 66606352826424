import { Keyboard, Mousewheel } from 'swiper'
import type { SwiperProps } from 'swiper/react'
import { Swiper } from 'swiper/react'

export function FullScreenSwiper({ modules, ...props }: SwiperProps) {
  return (
    <Swiper
      resistanceRatio={0}
      spaceBetween={0}
      slidesPerView={1}
      watchSlidesProgress
      modules={[Keyboard, Mousewheel, ...(modules ?? [])]}
      keyboard={{ enabled: true }}
      mousewheel={{
        forceToAxis: true,
        sensitivity: 0.5,
        thresholdDelta: 10,
      }}
      threshold={10}
      {...props}
    />
  )
}
