import type { PropsWithChildren } from 'react'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import type { CheckoutConversionType } from '@nordic-web/gql'
import type { CheckoutUpgradeDiscounts, Package, PackageTier } from '@nordic-web/gql/src/types'
import type { PaymentMethodsResponse } from '@nordic-web/rest-codegen/generated/subscription'
import { useCheckoutPage } from '@/features/checkout/hooks/use-checkout-page'

export type CheckoutState = {
  hasAcceptedTerms: boolean
  setHasAcceptedTerms: (arg0: boolean) => void
  isCheckingOutWithBinding: boolean
  toggleBinding: (arg0: boolean) => void
  isAdFree: boolean
  setAdFree: (arg0: boolean) => void
  checkoutPackage?: Package
  selectedTier?: PackageTier
  resetCheckoutState: () => void
  conversionType?: CheckoutConversionType
  upgradeDiscounts?: CheckoutUpgradeDiscounts
  availablePaymentMethods?: PaymentMethodsResponse
  isWithAdsQuery?: string
  isWithBindingQuery?: string
}

export const CheckoutStateContext = createContext<CheckoutState | undefined>(undefined)

export const CheckoutStateProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false)
  const [isCheckingOutWithBinding, toggleBinding] = useState(false)
  const [isAdFree, setAdFree] = useState(false)
  const { data: checkoutData } = useCheckoutPage()

  const selectedTier = useMemo(() => {
    if (!checkoutData?.checkout) return

    return checkoutData.checkout.package.tiers.find((tier) => tier.adFree === isAdFree)
  }, [checkoutData, isAdFree])

  const { withAds, withAdFree, withBinding } = router.query
  useEffect(() => {
    if (withAds) {
      setAdFree(!(withAds === 'true'))
    }
    if (withAdFree) {
      setAdFree(withAdFree === 'true')
    }
    if (withBinding) {
      toggleBinding(withBinding === 'true')
    }
  }, [withAds, withAdFree, withBinding])

  const resetCheckoutState = useCallback(() => {
    setHasAcceptedTerms(false)
    toggleBinding(false)
    setAdFree(false)
  }, [])

  const availablePaymentMethods = checkoutData?.checkout?.availablePaymentMethods
    ? JSON.parse(checkoutData?.checkout?.availablePaymentMethods)
    : undefined

  return (
    <CheckoutStateContext.Provider
      value={{
        hasAcceptedTerms,
        setHasAcceptedTerms,
        isCheckingOutWithBinding,
        toggleBinding,
        isAdFree,
        setAdFree,
        selectedTier,
        resetCheckoutState,
        checkoutPackage: checkoutData?.checkout?.package,
        upgradeDiscounts: checkoutData?.checkout?.upgradeDiscounts,
        conversionType: checkoutData?.checkout?.conversion,
        availablePaymentMethods,
        isWithAdsQuery: typeof withAds === 'string' ? withAds : undefined,
        isWithBindingQuery: typeof withBinding === 'string' ? withBinding : undefined,
      }}
    >
      {children}
    </CheckoutStateContext.Provider>
  )
}

export const useCheckoutState = () => {
  const state = useContext(CheckoutStateContext)

  if (typeof state === 'undefined') {
    throw new Error('useCheckoutState must be used within a CheckoutStateContext')
  }

  return state
}
