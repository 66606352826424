import type { PackageTierLinkFieldsFragment, SinglePanelFieldsFragment } from '@nordic-web/gql'
import { MediaClassification, useSuggestedEpisodeQuery } from '@nordic-web/gql'
import type { LabelFactoryProps } from '@/components/label-factory'
import type { FavoriteMediaTypes } from '@/features/favorite/utils'
import { paths } from '@/helpers/paths'

type ParseLinkResult = {
  ctaHref?: string
  secondaryCtaHref?: string
  isLoading?: boolean
  mediaLabelData?: LabelFactoryProps['media']
  favoriteType?: FavoriteMediaTypes
  favoriteId?: string
  trackingId?: string
  shouldAlwaysShowCdpButton?: boolean
  upsellData?: {
    tierName?: string
    packageTierLink: PackageTierLinkFieldsFragment
  } | null
  assetId?: string
  hasAccess?: boolean
  isLiveContent?: boolean
}

export const useParseLink = (link: SinglePanelFieldsFragment['link']): ParseLinkResult => {
  const isSeriesLink = link?.__typename === 'SinglePanelSeriesLink'
  const { data: suggestedEpisodeData, loading: isLoading } = useSuggestedEpisodeQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isSeriesLink,
    variables: { id: isSeriesLink ? link.content.id : '' },
    ssr: false,
  })

  switch (link?.__typename) {
    case 'SinglePanelMovieLink':
      return {
        trackingId: link.content.id,
        favoriteType: link.content.__typename,
        favoriteId: link.content.id,
        ctaHref: paths.video.urlString({ assetId: link.content.id, slug: link.content.slug }),
        secondaryCtaHref: paths.program.urlString({ id: link.content.id, slug: link.content.slug }),
        upsellData: link.content?.upsell,
        mediaLabelData: link.content,
        assetId: link.content.id,
        hasAccess: link.content.access.hasAccess,
        isLiveContent: link.content.isLiveContent,
      }
    case 'SinglePanelSeriesLink': {
      const episode = suggestedEpisodeData?.series?.suggestedEpisode?.episode
      return {
        trackingId: link.content.id,
        favoriteType: link.content.__typename,
        favoriteId: link.content.id,
        ctaHref: episode ? paths.video.urlString({ assetId: episode.id, slug: episode.slug }) : undefined,
        secondaryCtaHref: paths.program.urlString({ id: link.content.id, slug: link.content.slug }),
        shouldAlwaysShowCdpButton: link.content.mediaClassification !== MediaClassification.News,
        upsellData: link.content?.upsell,
        mediaLabelData: {
          label: link.content.label ?? undefined,
          isLiveContent: episode?.isLiveContent,
          liveEventEnd: episode?.liveEventEnd,
          playableFrom: episode?.playableFrom,
          upsell: link.content.upsell,
        },
        isLoading,
        assetId: episode?.id,
        hasAccess: episode?.access.hasAccess,
        isLiveContent: episode?.isLiveContent,
      }
    }
    case 'SinglePanelEpisodeLink': {
      const seriesId = link.content.series?.id
      return {
        favoriteType: 'Series',
        trackingId: link.content.id,
        favoriteId: link.content.series?.id,
        ctaHref: paths.video.urlString({ assetId: link.content.id, slug: link.content.slug }),
        upsellData: link.content?.upsell,
        secondaryCtaHref: seriesId
          ? paths.program.urlString({ id: seriesId, slug: link.content.series?.slug })
          : undefined,
        mediaLabelData: link.content,
        assetId: link.content.id,
        hasAccess: link.content.access.hasAccess,
        isLiveContent: link.content.isLiveContent,
      }
    }

    case 'SinglePanelClipLink': {
      const parentId = link.content.parent?.id
      return {
        trackingId: link.content.id,
        favoriteId: link.content.id,
        ctaHref: paths.clips.urlString({ assetId: link.content.id, slug: link.content.slug }),
        secondaryCtaHref: parentId
          ? paths.program.urlString({ id: parentId, slug: link.content.parent?.slug })
          : undefined,
      }
    }
    case 'SinglePanelPageLink':
      return {
        trackingId: link.content.id,
        secondaryCtaHref: paths.page.urlString({ id: link.content.id }),
      }
    case 'SinglePanelSportEventLink':
      return {
        trackingId: link.content.id,
        ctaHref: paths.video.urlString({ assetId: link.content.id, slug: link.content.slug }),
        secondaryCtaHref: paths.program.urlString({ id: link.content.id, slug: link.content.slug }),
        upsellData: link.content?.upsell,
        mediaLabelData: link.content,
        assetId: link.content.id,
        hasAccess: link.content.access.hasAccess,
        isLiveContent: link.content.isLiveContent,
      }
    case 'SinglePanelChannelLink':
      return {
        trackingId: link.content.id,
        ctaHref: paths.video.urlString({ assetId: link.content.id, slug: link.content.slug }),
        assetId: link.content.id,
        hasAccess: link.content.access.hasAccess,
        isLiveContent: true,
      }
    default: {
      return {}
    }
  }
}
