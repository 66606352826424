import { createGlobalStyle, useTheme } from 'styled-components'
import { GlobalStyles as NordicWebGlobalStyles } from '@nordic-web/ui-styles'
import { globalAnimations } from '@/styles/animations/global'
import { routerProgressBar } from './router-progress-bar'

const ApplicationGlobalStyles = createGlobalStyle`
  ${routerProgressBar}
  ${globalAnimations}
`

export const GlobalStyles = () => {
  const theme = useTheme()

  return (
    <>
      <NordicWebGlobalStyles theme={theme} />
      <ApplicationGlobalStyles />
    </>
  )
}
