import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { usePanelQuery } from '@nordic-web/gql'
import { FullPageCircleSpinner, NavigationTabs } from '@nordic-web/ui-components'
import { TableauDetailed } from '@/features/channel-epg-panel/tableau-detailed'
import { TableauOverview } from '@/features/channel-epg-panel/tableau-overview'
import { paths } from '@/helpers/paths'

type EpgChannelPanelProps = {
  panelId: string
}

// Needs to be synced with paths.js
const TABLEAU_PARAMS = ['aikataulu', 'tabla']
export const useIsTableau = () => {
  const router = useRouter()
  const tableauParam = router.query.params?.[0]
  return !!tableauParam && TABLEAU_PARAMS.includes(tableauParam)
}

export const ChannelEpgPanel = ({ panelId }: EpgChannelPanelProps) => {
  const t = useTranslations()
  const shouldShowDetailedTab = useIsTableau()

  const { data, loading: isLoading } = usePanelQuery({
    ssr: false,
    fetchPolicy: 'network-only',
    variables: {
      panelId,
      // If we notice there will be close to 100 channels in the channels page, we should switch to infinite scroll or a load more button
      limit: 100,
      offset: 0,
    },
  })

  if (isLoading) return <FullPageCircleSpinner />

  const channels =
    data?.panel?.__typename === 'ChannelPanel' ? data.panel.content.items.map((item) => item.content) : []

  return (
    <NavigationTabs
      nwInitActiveTab={shouldShowDetailedTab ? 1 : 0}
      nwTabs={[
        {
          id: 'now',
          label: t('channels__overview'),
          href: paths.channels.urlString(),
        },
        {
          id: 'tableau',
          label: t('channels__tableau'),
          href: paths.channelsTableau.urlString(),
        },
      ]}
    >
      {shouldShowDetailedTab ? <TableauDetailed channels={channels} /> : <TableauOverview channels={channels} />}
    </NavigationTabs>
  )
}
