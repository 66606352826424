import type { ApolloCache } from '@apollo/client'
import { useApolloClient } from '@apollo/client'
import update from 'immutability-helper'
import type {
  ContinueWatchingPanelQuery,
  ContinueWatchingPanelQueryVariables,
  MarkAsSeenInput,
  MarkAsSeenMutation,
  MarkAsSeenMutationVariables,
  RemoveFromContinueWatchingMutation,
  RemoveFromContinueWatchingMutationVariables,
} from '@nordic-web/gql'
import { ContinueWatchingPanelDocument, MarkAsSeenDocument, RemoveFromContinueWatchingDocument } from '@nordic-web/gql'
import type { ContinueWatchingPanelItem } from '@nordic-web/gql/src/types'

type UpdateContinueWatchingCacheProps = {
  findIndexToUpdate: (items: ContinueWatchingPanelItem[]) => number | undefined
  nextEntry: ContinueWatchingPanelItem | null
  panelId: string
  cache: ApolloCache<LegitimateAny>
}

const updateContinueWatchingCache = ({
  findIndexToUpdate,
  nextEntry,
  panelId,
  cache,
}: UpdateContinueWatchingCacheProps) => {
  const data = cache.readQuery<ContinueWatchingPanelQuery, ContinueWatchingPanelQueryVariables>({
    query: ContinueWatchingPanelDocument,
    variables: {
      panelId,
      offset: 0,
      limit: 100,
    },
  })

  if (data?.panel?.__typename !== 'ContinueWatchingPanel') return

  const indexAt = findIndexToUpdate(data.panel.content.items)

  if (typeof indexAt === 'undefined') return

  let newPanel

  if (nextEntry) {
    newPanel = update(data, {
      panel: {
        content: {
          items: {
            [indexAt]: { $set: nextEntry },
          },
        },
      },
    })
  } else {
    newPanel = update(data, {
      panel: {
        content: {
          items: {
            $splice: [[indexAt, 1]],
          },
        },
      },
    })
  }

  cache.writeQuery<ContinueWatchingPanelQuery, ContinueWatchingPanelQueryVariables>({
    query: ContinueWatchingPanelDocument,
    data: newPanel,
    overwrite: true,
    variables: {
      panelId: data.panel.id,
      limit: 100,
      offset: 0,
    },
  })
}

export const useContinueWatching = (panelId: string) => {
  const apolloClient = useApolloClient()

  const markAsSeen = async ({ id }: MarkAsSeenInput) => {
    await apolloClient.mutate<MarkAsSeenMutation, MarkAsSeenMutationVariables>({
      mutation: MarkAsSeenDocument,
      variables: { input: { id } },
      update: (cache, response) => {
        const nextEntry = response.data?.markAsSeen.nextEntry || null

        updateContinueWatchingCache({
          findIndexToUpdate: (items) => {
            return items.findIndex((item) => item.media.content.id === id)
          },
          nextEntry,
          panelId,
          cache,
        })
      },
    })
  }

  const remove = async ({ continueWatchingEntryId }: { continueWatchingEntryId: string }) => {
    await apolloClient.mutate<RemoveFromContinueWatchingMutation, RemoveFromContinueWatchingMutationVariables>({
      mutation: RemoveFromContinueWatchingDocument,
      variables: { continueWatchingEntryId },
      update: (cache) => {
        updateContinueWatchingCache({
          findIndexToUpdate: (items) => {
            return items.findIndex((item) => item.continueWatchingEntryId === continueWatchingEntryId)
          },
          nextEntry: null,
          panelId,
          cache,
        })
      },
    })
  }

  return { markAsSeen, remove }
}
