import styled from 'styled-components'
import { Button } from '@nordic-web/ui-components'
import { detectTouch } from '@/utils/detect-touch'

type Props = {
  direction: 'left' | 'right'
  show: boolean
  onClick: () => void
}

const isTouchDevice = detectTouch()

export const NavigationArrow = ({ direction, show, onClick }: Props) => {
  return (
    <Wrapper $direction={direction} $show={isTouchDevice ? false : show} className="navigation-arrow">
      <Button
        nwVariant="dark"
        nwIconFirst={direction === 'right' ? 'chevron-right' : 'chevron-left'}
        onClick={onClick}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $direction: 'left' | 'right'; $show: boolean }>(({ $direction, $show }) => ({
  width: 60,
  position: 'absolute',
  top: '50%',
  display: 'flex',
  justifyContent: 'center',
  transform: 'translateY(-50%)',
  zIndex: 1,
  opacity: $show ? 1 : 0,
  transition: 'opacity 250ms',
  ...($direction === 'right' ? { right: 0 } : { left: 0 }),
  '&:hover': {
    opacity: $show ? 1 : 0,
  },
}))
