import { FavoriteType } from '@nordic-web/gql'
import type { FavoriteItem, Favorites } from './common-types'

export type FavoriteMediaTypes = 'Movie' | 'Series'

function favoriteMatchesId(favorite: FavoriteItem, id: string) {
  switch (favorite.__typename) {
    case 'FavoriteMovieItem':
      return favorite.content.id === id
    case 'FavoriteSeriesItem':
      return favorite.content.id === id
    default:
      return false
  }
}

function getFavorite(favorites: Favorites, favoriteId: string) {
  return favorites.find((f) => favoriteMatchesId(f, favoriteId))
}

export function isFavorite(favorites: Favorites, favoriteId: string) {
  return !!getFavorite(favorites, favoriteId)
}

export function removeFavorite(favorites: Favorites, favoriteId: string) {
  const favorite = getFavorite(favorites, favoriteId)
  const newFavorites = favorite ? favorites.filter((f) => !favoriteMatchesId(f, favoriteId)) : favorites
  return {
    favorite,
    favorites: newFavorites,
  }
}

export const mapTypenameToFavoriteType = (type: FavoriteMediaTypes) => {
  switch (type) {
    case 'Movie':
      return FavoriteType.Movie
    case 'Series':
      return FavoriteType.Series
  }
}
