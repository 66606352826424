import { useState } from 'react'
import dynamic from 'next/dynamic'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
import { BackgroundImage } from '@/components/background-image/background-image'
import { useIsBelowTablet } from '@/hooks/use-breakpoint'

type Props = {
  assetId: string
  setHasPlayerError: (hasError: boolean) => void
  desktopImage?: string
  mobileImage?: string
  color?: string
  imagePriority?: boolean
}

export const LazyHeroVideo = ({
  assetId,
  setHasPlayerError,
  desktopImage,
  mobileImage,
  color,
  imagePriority: imagePriority,
}: Props) => {
  const { ref, inView: isInView } = useInView({ threshold: 0.7 })
  const isMobile = useIsBelowTablet()
  const [hasVideoLoaded, setHasVideoLoaded] = useState(false)

  return (
    <>
      <ImageContainer $visible={!hasVideoLoaded || !isInView} $backgroundColorHex={color} ref={ref}>
        {!isMobile && desktopImage && <BackgroundImage src={desktopImage} imagePriority={imagePriority} />}
        {isMobile && mobileImage && <BackgroundImage src={mobileImage} imagePriority={imagePriority} />}
      </ImageContainer>
      {isInView && (
        <Video
          assetId={assetId}
          setHasPlayerError={setHasPlayerError}
          hasVideoLoaded={hasVideoLoaded}
          setHasVideoLoaded={setHasVideoLoaded}
        />
      )}
    </>
  )
}

// Needs to be dynamic because of the video player
export const Video = dynamic(() => import('./hero-video').then((mod) => mod.HeroVideo), {
  ssr: false,
  loading: () => <div style={{ display: 'none' }} />,
})

const ImageContainer = styled.div<{ $visible: boolean; $backgroundColorHex?: string }>(
  ({ $visible, $backgroundColorHex }) => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    backgroundColor: $backgroundColorHex ? $backgroundColorHex : undefined,
    opacity: $visible ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',
    zIndex: 1,
  })
)
