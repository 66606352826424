import type AdyenCheckout from '@adyen/adyen-web'
import type { PaymentDetailsRequest, ResultCodeEnum } from '@nordic-web/rest-codegen/generated/subscription'

export type AdyenCheckoutOptions = Parameters<typeof AdyenCheckout>[0]
export type AdyenCheckoutError = Parameters<NonNullable<AdyenCheckoutOptions['onError']>>[0]

export enum AdyenComponentErrorNames {
  NETWORK_ERROR = 'NETWORK_ERROR',
  CANCEL = 'CANCEL',
  IMPLEMENTATION_ERROR = 'IMPLEMENTATION_ERROR',
  ERROR = 'ERROR',
}

export class FakeAdyenCheckoutError extends Error {
  cause: unknown
  constructor(type: AdyenComponentErrorNames) {
    super(type)
    this.name = type
  }
}

export type AdyenComponentError = {
  name: AdyenComponentErrorNames
  message?: string
}

export type AdyenResponseObject = {
  action?: string
  resultCode: ResultCodeEnum
  transactionId?: string
}

export type AdyenState = {
  data: Record<string, unknown>
  isValid?: boolean
}

export type AdyenDetailsState = {
  data: PaymentDetailsRequest
  isValid?: boolean
}

export enum Outcome {
  SUCCESS = 'success',
  ERROR = 'error',
  CONFIRM = 'confirm',
}

export enum PaymentMethodEnum {
  CARD = 'card',
  KLARNA_PAYNOW = 'klarna_paynow',
  KLARNA_PAYLATER = 'klarna',
  STORED_CARD = 'stored_card',
}

export enum OrderPeriods {
  DAY = 'P1D',
  WEEK = 'P1W',
  MONTH = 'P1M',
}
