import type { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Stack, TypographyText } from '@nordic-web/ui-components'

type MetaPropertyListProps = {
  compact: boolean
  oneLine?: boolean
}

export const MetaPropertyList = ({ children, compact, oneLine = false }: PropsWithChildren<MetaPropertyListProps>) => {
  return (
    <Wrapper nwColor="secondary" nwVariant={compact ? 'smallText' : 'meta'}>
      <StackWrapper nwDirection="row" nwGap={compact ? 4 : [2, 4, 6]} oneLine={oneLine}>
        {children}
      </StackWrapper>
    </Wrapper>
  )
}

const StackWrapper = styled(Stack)<{ oneLine: boolean }>(({ oneLine, theme }) => ({
  rowGap: theme.space(1),
  flexWrap: oneLine ? 'nowrap' : 'wrap',
  '> *': {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
}))

const Wrapper = styled(TypographyText)({
  display: 'flex',
})
