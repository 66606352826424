import React, { createContext, useContext } from 'react'
import { InView } from 'react-intersection-observer'
import { getPageName } from '@/helpers/get-page-name'
import { usePanelTracking } from '@/tracking/panel-tracking'
import { dataLayerPush, getDataLayer } from '@/tracking/tracking'

type AssetTrackingContextProps = {
  trackOnAssetClick: () => void
}

const AssetTrackingContext = createContext<AssetTrackingContextProps | undefined>(undefined)

type AssetTrackerProps = {
  content_media_id: string
  uniqueFlag?: boolean
  upsell?: {
    item: string
    package?: string
  } | null
  custom_panel_id?: string
  children?: ({ trackOnAssetClick }: { trackOnAssetClick: () => void }) => React.JSX.Element | null
}

export const AssetTracking = ({
  content_media_id,
  custom_panel_id,
  uniqueFlag = false,
  upsell = null,
  children,
}: AssetTrackerProps) => {
  const parentPanelInfo = usePanelTracking()
  const panel_id = custom_panel_id || parentPanelInfo?.panel_id
  const panel_row_index = parentPanelInfo?.panel_row_index
  const page_name = getPageName()
  const upsell_item = upsell?.item
  const upsell_package = upsell?.package

  const isAssetAlreadyTracked = (assetImpression: AssetTrackerProps) =>
    assetImpression.content_media_id === content_media_id

  const inViewChanged = (inView: boolean) => {
    if (inView) {
      const dataLayer = getDataLayer()

      if (uniqueFlag && dataLayer.some(isAssetAlreadyTracked)) return

      dataLayerPush({
        event: 'asset_impression',
        panel_id,
        panel_row_index,
        content_media_id,
        page_name,
      })

      if (upsell) {
        dataLayerPush({
          event: 'upsell_impression',
          content_media_id,
          page_name,
          upsell_item,
          upsell_package,
        })
      }
    }
  }

  const trackOnAssetClick = () => {
    dataLayerPush({
      event: 'asset_click',
      panel_id,
      panel_row_index,
      page_name,
      content_media_id,
    })

    if (upsell) {
      dataLayerPush({
        event: 'upsell_click',
        content_media_id,
        page_name,
        upsell_item,
        upsell_package,
      })
    }
  }

  return (
    <AssetTrackingContext.Provider value={{ trackOnAssetClick }}>
      <InView onChange={inViewChanged} style={{ width: '100%' }} threshold={0.9} triggerOnce>
        {children?.({ trackOnAssetClick })}
      </InView>
    </AssetTrackingContext.Provider>
  )
}

export const useTrackOnAssetClick = () => {
  const context = useContext(AssetTrackingContext)

  if (typeof context === 'undefined') {
    throw new Error('useTrackOnAssetClick must be used within a AssetTrackingContext')
  }

  return context
}
