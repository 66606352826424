import { type FC, useState } from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { type ChannelFieldsFragment } from '@nordic-web/gql'
import { Button, Stack } from '@nordic-web/ui-components'
import { ScrollArea } from '@nordic-web/ui-components/src/components/util/util-horizontal-scroll-area/util-horizontal-scroll-area.styled'
import { DayMenuBar, formatDateQueryParam } from '@/components/day-menu-bar'
import { PageMargin } from '@/components/scaffolding/page-margin'
import { ChannelPanelGrid } from '@/features/channel-epg-panel/components/channel-panel-grid'
import { ChannelUpgradeBanner } from '@/features/channel-epg-panel/components/channel-upgrade-banner'
import { LargeScreenChannelEpg } from '@/features/channel-epg-panel/components/large-screen-channel-epg'
import { SmallScreenChannelEpg } from '@/features/channel-epg-panel/components/small-screen-channel-epg'
import { SmallScreenChannelSelect } from '@/features/channel-epg-panel/components/small-screen-channel-select'
import { paths } from '@/helpers/paths'
import { useIsAboveTablet } from '@/hooks/use-breakpoint'
import { BlurryHeaderBackground } from '@/layouts/header/header'
import { stringFromQueryParam } from '@/utils/query-string'

type FullTableauProps = {
  channels: ChannelFieldsFragment[]
}

const ButtonStack = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.space(5),
}))

const StickyButtonContainer = styled.div<{ adjustPadding: boolean }>(({ adjustPadding }) => ({
  position: 'sticky',
  top: 'var(--header-height)',
  zIndex: 1,
  ...(adjustPadding && {
    paddingRight: '5vw',
    [String(ScrollArea)]: {
      paddingRight: 0,
    },
  }),
}))

const DayBarContainer = styled.div({
  overflow: 'auto',
})

const ExpandButtonContainer = styled.div({
  flexShrink: 0,
})

const UpgradeBannerContainer = styled(PageMargin)(({ theme }) => ({
  paddingBottom: theme.space(5),
}))

export const TableauDetailed: FC<FullTableauProps> = ({ channels }) => {
  const t = useTranslations()
  const [shouldShowFullDay, setShouldShowFullDay] = useState(false)
  const router = useRouter()
  const formattedToday = formatDateQueryParam(new Date())
  const currentDay = stringFromQueryParam(router.query.d) ?? formattedToday
  const currentDayMs = new Date(currentDay).getTime()
  const hasChannelWithoutAccess = channels.some((channel) => !channel.access.hasAccess)
  const shouldShowLargeScreenLayout = useIsAboveTablet()
  const [selectedChannel, setSelectedChannel] = useState(channels[0])

  return (
    <>
      {hasChannelWithoutAccess && (
        <UpgradeBannerContainer>
          <ChannelUpgradeBanner />
        </UpgradeBannerContainer>
      )}
      <StickyButtonContainer adjustPadding={shouldShowLargeScreenLayout}>
        <BlurryHeaderBackground hasTransparentHeader={false}>
          <Stack nwGap={5}>
            {!shouldShowLargeScreenLayout && selectedChannel && (
              <SmallScreenChannelSelect
                channels={channels}
                selectedChannel={selectedChannel}
                onChannelClick={setSelectedChannel}
              />
            )}
            <ButtonStack nwDirection="row" nwGap={2}>
              <DayBarContainer>
                <DayMenuBar
                  futureDaysToShow={7}
                  pastDaysToShow={-7}
                  selectedDay={currentDay}
                  getHref={(day) => paths.channelsTableau.urlString({ d: day })}
                />
              </DayBarContainer>
              {shouldShowLargeScreenLayout && (
                <ExpandButtonContainer>
                  <Button
                    nwIconFirst={shouldShowFullDay ? 'minimize-vertical' : 'maximize-vertical'}
                    nwVariant="subtle"
                    onClick={() => setShouldShowFullDay(!shouldShowFullDay)}
                  >
                    {shouldShowFullDay ? t('channels__collapse_day') : t('channels__expand_day')}
                  </Button>
                </ExpandButtonContainer>
              )}
            </ButtonStack>
          </Stack>
        </BlurryHeaderBackground>
      </StickyButtonContainer>

      <PageMargin>
        {shouldShowLargeScreenLayout ? (
          <ChannelPanelGrid>
            {channels.map((channel) => (
              <LargeScreenChannelEpg
                shouldShowFullDay={shouldShowFullDay}
                date={currentDayMs}
                key={channel.id}
                channel={channel}
              />
            ))}
          </ChannelPanelGrid>
        ) : (
          <>{selectedChannel && <SmallScreenChannelEpg date={currentDayMs} channel={selectedChannel} />}</>
        )}
      </PageMargin>
    </>
  )
}
