import React, { useMemo, useState } from 'react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'
import type { ContinueWatchingPanelItem } from '@nordic-web/gql/src/types'
import { ButtonCollapse, Label, ProgressBar } from '@nordic-web/ui-components'
import {
  CardButtonContainer,
  CardContainer,
  CardFooterContainer,
  CardFooterTextContainer,
  CardImageContainer,
  CardLabelContainer,
  CardMainText,
  CardMobileMenuToggle,
  CardProgressBarContainer,
  CardSecondaryText,
} from '@nordic-web/ui-components'
import { useContinueWatching } from '@nordic-web/utils/hooks/use-continue-watching'
import { FadeInImage } from '@/components/fade-in-image'
import { usePrefetchVideoQuery } from '@/components/prefetch-links/video-prefetch-link'
import { TruncateLines } from '@/components/truncate-lines/truncate-lines'
import { getParentInfo } from '@/helpers/get-parent-info'
import { paths } from '@/helpers/paths'
import { AssetTracking } from '@/tracking/asset-tracking'
import { ContinueWatchingDialog } from './continue-watching-dialog'

type ContinueWatchingCardProps = {
  item: ContinueWatchingPanelItem
  panelId: string
}

export const ContinueWatchingCard = ({ item, panelId }: ContinueWatchingCardProps) => {
  const t = useTranslations()
  const [shouldShowDialog, setShouldShowDialog] = useState(false)
  const { media, labelText, progress, continueWatchingEntryId } = item

  const image = useMemo(() => {
    if (media.__typename === 'ContinueWatchingEpisodeItem') {
      return media.content.series?.images.main16x9Annotated.sourceEncoded ?? media.content.images.main16x9.sourceEncoded
    } else if (media.__typename === 'ContinueWatchingMovieItem') {
      return media.content.images.main16x9Annotated.sourceEncoded
    }
    return media.content.images.main16x9.sourceEncoded
  }, [media])

  const asset = media.content
  const { title, id, slug } = asset
  const parentInfo = getParentInfo(asset)
  const assetProgress = progress?.percent || 0
  const { markAsSeen, remove } = useContinueWatching(panelId)
  const secondaryText = assetProgress ? progress?.timeLeft : asset.duration?.readableShort

  const prefetch = usePrefetchVideoQuery(id)
  return (
    <>
      <AssetTracking content_media_id={id}>
        {({ trackOnAssetClick }) => (
          <CardContainer
            onMouseOver={prefetch}
            href={paths.video.urlString({ assetId: id, slug })}
            onClick={() => trackOnAssetClick()}
          >
            <CardImageContainer>
              {labelText && (
                <CardLabelContainer>
                  <Label nwVariant="announcement" nwPlacement="corner">
                    {labelText}
                  </Label>
                </CardLabelContainer>
              )}
              <FadeInImage alt={title} source={image} />
              <CardButtonContainer>
                <ButtonCollapse
                  data-test="continue-seen"
                  nwIcon="check"
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault()
                    e.stopPropagation()
                    markAsSeen({ id })
                  }}
                >
                  {t('continue_watching__mark_as_seen')}
                </ButtonCollapse>
                <ButtonCollapse
                  data-test="continue-remove"
                  nwIcon="trash"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    remove({ continueWatchingEntryId })
                  }}
                >
                  {t('continue_watching__remove_from_continue_watching')}
                </ButtonCollapse>
                {parentInfo && (
                  <Link
                    href={paths.program.urlString({ id: parentInfo.id, slug: parentInfo.slug })}
                    passHref
                    legacyBehavior
                  >
                    <ButtonCollapse data-test="continue-program-page" nwIcon="alert-info-outlined">
                      {t('general__program_page_cta')}
                    </ButtonCollapse>
                  </Link>
                )}
              </CardButtonContainer>
              {assetProgress !== 100 && (
                <CardProgressBarContainer>
                  <ProgressBar percentage={assetProgress} />
                </CardProgressBarContainer>
              )}
            </CardImageContainer>
            <CardFooterContainer>
              <CardFooterTextContainer>
                <CardMainText>
                  <TruncateLines lines={1}>{title}</TruncateLines>
                </CardMainText>
                {secondaryText && <CardSecondaryText>{secondaryText}</CardSecondaryText>}
              </CardFooterTextContainer>
              <CardMobileMenuToggle nwOnClick={() => setShouldShowDialog(true)} />
            </CardFooterContainer>
          </CardContainer>
        )}
      </AssetTracking>
      <ContinueWatchingDialog
        isOpen={shouldShowDialog}
        onClose={() => setShouldShowDialog(false)}
        mediaId={id}
        slug={slug}
        title={title}
        parentId={parentInfo?.id}
        parentSlug={parentInfo?.slug}
        panelId={panelId}
        continueWatchingEntryId={continueWatchingEntryId}
      />
    </>
  )
}
