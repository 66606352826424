import { useRef, useState } from 'react'
import type { PropsWithChildren } from 'react'
import styled from 'styled-components'
import {
  type ImageFieldsFullFragment,
  type SinglePanelFieldsFragment,
  type TrailerFieldsFragment,
} from '@nordic-web/gql'
import type { StackGap } from '@nordic-web/ui-components'
import { Stack } from '@nordic-web/ui-components'
import { desktop, extraLargeDesktop, tablet } from '@nordic-web/ui-styles'
import { isTypeInUnion } from '@nordic-web/utils/typescript'
import { BackgroundTrailer } from '@/components/background-trailer'
import type { TrailerVideoController } from '@/components/background-trailer'
import { BackgroundTrailerControls } from '@/components/background-trailer/background-trailer-controls'
import { useShouldDevicePlayVideo } from '@/components/background-trailer/use-should-device-play-video'
import { LazyHeroVideo } from '@/components/hero/lazy-hero-video'
import { PageMargin } from '@/components/scaffolding/page-margin'
import { useParseLink } from '@/features/panel-factory/hooks/single-module-link-parser'
import { multiMaskBaseObj } from '@/styles/utils/masking'

export const heroImageRatioBreakpoint = tablet

type HeroProps = PropsWithChildren<{
  image16x9?: ImageFieldsFullFragment
  image2x3?: ImageFieldsFullFragment
  trailers?: TrailerFieldsFragment | null
  testId?: string
  imagePriority?: boolean
  gap?: StackGap
  onTrailerEnd?: () => void
  onTrailerPlay?: () => void
  link?: SinglePanelFieldsFragment['link']
  shouldPlayVideo?: boolean | null
  isActive?: boolean
}>

export const Hero = ({
  image16x9,
  image2x3,
  trailers,
  testId,
  imagePriority,
  children,
  gap = 4,
  onTrailerEnd,
  onTrailerPlay,
  link,
  shouldPlayVideo,
  isActive = false,
}: HeroProps) => {
  const hasTrailers = trailers?.mp4 || trailers?.webm
  const videoRef = useRef<TrailerVideoController | null>(null)
  const { assetId, hasAccess, isLiveContent } = useParseLink(link)
  const [hasPlayerError, setHasPlayerError] = useState(false)
  const shouldDevicePlayVideo = useShouldDevicePlayVideo()

  const isValidVideoType = isTypeInUnion(
    link,
    'SinglePanelMovieLink',
    'SinglePanelEpisodeLink',
    'SinglePanelSportEventLink',
    'SinglePanelChannelLink',
    'SinglePanelSeriesLink'
  )
  const shouldShowVideo =
    shouldPlayVideo &&
    isValidVideoType &&
    isActive &&
    assetId &&
    hasAccess &&
    isLiveContent && // For now we only have this feature for Live content. We need to check with AdTech if we can have UI above VODs with ads
    shouldDevicePlayVideo &&
    !hasPlayerError

  return (
    <Wrapper data-testid={testId}>
      <VideoBackgroundContainer>
        {shouldShowVideo ? (
          <LazyHeroVideo
            assetId={assetId}
            setHasPlayerError={setHasPlayerError}
            desktopImage={image16x9?.sourceEncoded}
            mobileImage={image2x3?.sourceEncoded}
            imagePriority={imagePriority}
            color={image2x3?.meta?.muteBgColor?.hex}
          />
        ) : (
          <BackgroundTrailer
            ref={videoRef}
            desktopImage={image16x9?.sourceEncoded}
            mobileImage={image2x3?.sourceEncoded}
            trailers={trailers}
            color={image2x3?.meta?.muteBgColor?.hex}
            imagePriority={imagePriority}
            onTrailerEnd={onTrailerEnd}
            onTrailerPlay={onTrailerPlay}
          />
        )}
      </VideoBackgroundContainer>

      <Content nwJustifyContent="flex-end">
        <PageMargin>
          <Stack nwJustifyContent="space-between" nwDirection="row" nwAlignItems="flex-end">
            <ChildrenStack nwAlignItems="flex-start" nwGap={gap}>
              {children}
            </ChildrenStack>
            {(hasTrailers || shouldShowVideo) && (
              <BackgroundTrailerControls trailerVideoController={videoRef.current} />
            )}
          </Stack>
        </PageMargin>
      </Content>
    </Wrapper>
  )
}

const ChildrenStack = styled(Stack)({
  flex: 1,
})

const Wrapper = styled.div(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.space(6),
}))

const topGradient = 'linear-gradient(178deg, transparent, black 250px)'

const VideoBackgroundContainer = styled.div({
  pointerEvents: 'none',
  aspectRatio: '2 / 3',
  maskImage: `${topGradient}, linear-gradient(black 40%, rgba(0, 0, 0, 0.41) 74%, transparent 100%)`,
  maskSize: '100% auto, 100% auto',
  position: 'absolute',
  width: '100%',
  overflow: 'hidden',
  [heroImageRatioBreakpoint]: {
    aspectRatio: '16 / 9',
    maskImage: `${topGradient}, url(/static/promo-overlay-large-1.svg), url(/static/promo-overlay-large-2.svg)`,
    maskSize: '100% auto, cover, cover',
  },
  ...multiMaskBaseObj,
})

const heroRatioStyles = {
  aspectRatio: '2 / 3',
  [heroImageRatioBreakpoint]: {
    aspectRatio: '16 / 9',
  },
  // Adjust the ratio a bit to keep content below the hero above the fold
  [desktop]: {
    aspectRatio: '16 / 7',
  },
  [extraLargeDesktop]: {
    aspectRatio: '16 / 6',
  },
}

const Content = styled(Stack)({
  position: 'relative',
  width: '100%',
  ...heroRatioStyles,
})

export const HeroRatioContainer = styled.div({
  ...heroRatioStyles,
})
