import { useState } from 'react'
import styled from 'styled-components'
import * as ServiceMessagesService from '@nordic-web/rest-codegen/generated/service-messages'
import { useIsClientSide } from '@nordic-web/utils/hooks/use-is-client-side'
import { MessageComponent as Message } from '@/components/service-messages/site-message'
import { nextConfig } from '@/helpers/env'
import { useLocalStorage } from '@/hooks/use-local-storage'
import { supportedBrowsers } from '@/utils/generated/supported-browser-regexp'
import { UNSUPPORTED_BROWSER_MESSAGE_ID } from './service-messages.constants'
import { useServiceMessages } from './use-service-messages'

ServiceMessagesService.OpenAPI.BASE = nextConfig.string('SERVICE_MESSAGES_API')

const ServiceWrapper = styled.div({
  width: '100%',
  zIndex: 50,
  position: 'fixed',
  bottom: 0,
})

export const ServiceMessages = () => {
  const isClientSide = useIsClientSide()
  const { data } = useServiceMessages()
  const [hiddenWarningMessageIds, setHiddenWarningMessageIds] = useLocalStorage<string[]>('servicemessages', [])
  const [hiddenSevereMessageIds, setHiddenSevereMessageIds] = useState<string[]>([])

  if (!isClientSide) return null

  const serviceMessages = []
  const isSupportedBrowser = supportedBrowsers.test(navigator.userAgent)

  const messagesData = (data?.data ?? []).map((message) => {
    return {
      hideId: `${message.id}_${message.updatedAt}`,
      ...message,
    }
  })

  serviceMessages.push(...messagesData)

  if (!isSupportedBrowser) {
    serviceMessages.push({
      id: UNSUPPORTED_BROWSER_MESSAGE_ID,
      hideId: UNSUPPORTED_BROWSER_MESSAGE_ID,
      content: '',
      level: 'severe' as const,
      link: undefined,
    })
  }

  const hideWarningMessage = (id: string) => {
    setHiddenWarningMessageIds([...hiddenWarningMessageIds, id])
  }

  const hideSevereMessage = (id: string) => {
    setHiddenSevereMessageIds([...hiddenSevereMessageIds, id])
  }

  const allHiddenMessageIds = [...hiddenWarningMessageIds, ...hiddenSevereMessageIds]

  return (
    <ServiceWrapper>
      {serviceMessages
        .filter(({ hideId }) => !allHiddenMessageIds.includes(hideId))
        .map(({ id, hideId, content, link, level }) => {
          return (
            <Message
              id={id}
              key={id}
              content={content}
              onClose={() => (level === 'severe' ? hideSevereMessage(hideId) : hideWarningMessage(hideId))}
              link={link}
              level={level}
            />
          )
        })}
    </ServiceWrapper>
  )
}
